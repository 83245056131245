<template>
  <div>
    <!-- HeaderFlix end-->
    <div class="IndexBanner" style="height: 896px;">
      <div class="swiper-container swiper-container-horizontal">
        <div class="swiper-wrapper" style="transform: translate3d(-7680px, 0px, 0px); transition-duration: 0ms;">
          <div class="swiper-slide swiper-slide-duplicate swiper-slide-next swiper-slide-duplicate-prev"
            data-swiper-slide-index="2" style="width: 1920px;">
            <img src="./assets/15ac9f79-e16d-4d97-b7e5-07834ed4a66a.jpg" style="height: 896px;">
          </div>
          <div class="swiper-slide swiper-slide-duplicate-active" data-swiper-slide-index="0" style="width: 1920px;">
            <img src="./assets/ba3dd65f-8ee6-4782-b9f4-526cc6c8e8a6.jpg" style="height: 896px;">
          </div>
          <div class="swiper-slide" data-swiper-slide-index="1" style="width: 1920px;">
            <img src="./assets/9f4bcdc9-59c1-4b4a-aa21-ff1907f7c890.jpg" style="height: 896px;">
          </div>
          <div class="swiper-slide swiper-slide-prev swiper-slide-duplicate-next" data-swiper-slide-index="2"
            style="width: 1920px;">
            <img src="./assets/15ac9f79-e16d-4d97-b7e5-07834ed4a66a.jpg" style="height: 896px;">
          </div>
          <div class="swiper-slide swiper-slide-duplicate swiper-slide-active" data-swiper-slide-index="0"
            style="width: 1920px;">
            <img src="./assets/ba3dd65f-8ee6-4782-b9f4-526cc6c8e8a6.jpg" style="height: 896px;">
          </div>
        </div>
      </div>
      <!-- Add Pagination -->
      <div class="swiper-pagination swiper-pagination-clickable swiper-pagination-bullets"><span
          class="swiper-pagination-bullet swiper-pagination-bullet-active"></span><span
          class="swiper-pagination-bullet"></span><span class="swiper-pagination-bullet"></span></div>
    </div>
    <!-- IndexBanner end -->
    <div class="Sound_World move">
      <img src="./assets/addbk5.png" class="AddBk move">
      <div class="container">
        <p class="BaseP">
          荔枝集团打造了综合性的全球化音频生态系统，<br>致力于通过多样化产品组合满足用户对于音频娱乐以及在线社交的需求，<br>使每个人都能在全球化的音频生态系统中通过声音连接与互动。<br>荔枝公司于2020年1月在纳斯达克上市。
        </p>
        <h2 class="BaseH">声音世界</h2>
        <div class="clearfix">
          <a href="javascript:void" class="Sound_World_Fx">
            <div class="Sound_World_FxImg">
              <img src="./assets/a08eb78b-6d47-4d31-b3db-8667f47e9892.jpg">
              <div class="CenterPor">
                <div class="Sound_World_H">
                  <h3>内容</h3>
                  <p>更多元的声音内容</p>
                </div>
                <div class="Sound_World_Copy">
                  <h3>内容</h3>
                  <p>更多元的声音内容</p>
                  荔枝播客主张“更有观点的输出”，代表的群体特性是年轻、有趣、有个性、有态度。荔枝播客持续打造高质量有观点的音频内容，各行业意见领袖入局，更多元观点碰撞。荔枝播客与小鹏汽车合作的车载播客直播为中国首创，荔枝播客还与多家知名企业携手打造品牌播客，助力企业触达更多用户、扩大品牌声量，打造新媒介传播形式。
                </div>
              </div>
            </div>
          </a><a href="javascript:void" class="Sound_World_Fx">
            <div class="Sound_World_FxImg">
              <img src="./assets/82ab0613-acf8-4d98-87f6-6b1e5540f5e4.jpg">
              <div class="CenterPor">
                <div class="Sound_World_H">
                  <h3>社区</h3>
                  <p>更有趣的声音互动</p>
                </div>
                <div class="Sound_World_Copy">
                  <h3>社区</h3>
                  <p>更有趣的声音互动</p>
                  荔枝App构建了一个集聚两亿多年轻人的音频社区，通过声音互动让人们沉浸于声音的有趣和无限可能，打通了平台内用户与主播、用户与用户的高质量互动，释放用户价值，形成了UGC闭环声态圈。荔枝的音频社区是基于声音的兴趣聚集，是更精神、更有趣的社交满足，这样的社群也更有凝聚力。
                </div>
              </div>
            </div>
          </a><a href="javascript:void" class="Sound_World_Fx">
            <div class="Sound_World_FxImg">
              <img src="./assets/73ae2c96-d7db-467d-9bc7-330f494cf2ee.jpg">
              <div class="CenterPor">
                <div class="Sound_World_H">
                  <h3>社交</h3>
                  <p>更懂你的声音交流</p>
                </div>
                <div class="Sound_World_Copy">
                  <h3>社交</h3>
                  <p>更懂你的声音交流</p>
                  在线声音社交是最重要的网络社交方式之一
                  ，人们可以通过网络实时语音，聚在一起，分享彼此的生活点滴。TIYA是我们布局声音社交的全球化产品，它基于游戏、歌唱等各种场景切入声音社交，通过极简而贴切的产品设计，满足了用户日益丰富的语音社交需求。未来，我们期待着人们会在洗漱间的镜子中、在自动驾驶的智能汽车中、在随身配带的智能耳机中，无时无刻、无处不在地进行语音社交。
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
    <div class="Dynamic_Report">
      <div class="container">
        <h2 class="BaseH">公司新闻</h2>
        <div class="clearfix">
          <a href="javascript:void" target="_self" class="Dynamic_Report_Fx">
            <div class="Dynamic_Report_FxImg">
              <img src="./assets/21b37f84-072a-48a8-ac32-f9e44d9ddf73.png">
            </div>
            <p>2022/01/19</p>
            <h2>荔枝成功举办2021年度声典颁奖典礼，全方位支持内容创作者</h2>
          </a><a href="javascript:void" target="_self" class="Dynamic_Report_Fx">
            <div class="Dynamic_Report_FxImg">
              <img src="./assets/7d323ce3-0be9-437a-a6ad-24f6415c6211.jpg">
            </div>
            <p>2022/01/04</p>
            <h2>技术创新赋能业务发展——荔枝集团2021年全年回顾</h2>
          </a><a href="javascript:void" target="_self" class="Dynamic_Report_Fx">
            <div class="Dynamic_Report_FxImg">
              <img src="./assets/6417753b-c7ec-453c-8c3a-cc1aebc9118e.png">
            </div>
            <p>2021/10/25</p>
            <h2>荔枝播客加入华为鸿蒙生态</h2>
          </a>
        </div>
      </div>
    </div>
    <div class="About_Lizhi">
      <div class="container">
        <div class="About_LizhiCt clearfix">
          <div class="About_LizhiCtR">
            <h2>关于荔枝</h2>
            <p>荔枝集团打造了综合性的全球化音频生态系统，致力于通过多样化产品组合满足用户对于音频娱乐以及在线社交的需求，使每个人都能在全球化的音频生态系统中通过声音连接与互动。荔枝公司于2020年1月在纳斯达克上市。
            </p>
          </div>
          <img class="About_LizhiCt_Img" src="./assets/img6.jpg">
        </div>
        <div class="Voice_Com" onclick="window.location=&#39;/develop/index.html&#39;"><img src="./assets/img7.jpg"
            width="100%">
          <div class="CenterPor">
            <h3>用声音，在一起</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from '../../configCenter.json'
export default {
  mounted() {
    document.title = config.title || config.companyFullName
    function handleJs() {
      if (window.innerWidth < 1023) {
        return
      } else {
        var HeaderFlix = window.$(".HeaderFlix").height();
        var Height = document.documentElement.clientHeight - HeaderFlix;
        window.$(".IndexBanner .swiper-slide img,.IndexBanner").height(Height);
      }
    }
    handleJs();
    new window.Swiper('.IndexBanner .swiper-container', {
      speed: 1000,
      autoplay: 3000,
      loop: true,
      pagination: '.swiper-pagination',
      paginationClickable: true
    });
  }
}
</script>

<style>
@import './assets/reset.css';
@import './assets/pc.css';
@import './assets/phone.css';
@import './assets/swiper-3.4.2.min.css';
</style>